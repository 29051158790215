import React from 'react';
import ReactDOM from 'react-dom/client';
import NavBar from './componentes/NavBar';
import Hero from './componentes/Hero';
import Nosotros from './componentes/Nosotros';
import Servicios from './componentes/Servicios';
import Contacto from './componentes/Contacto';
import Footer from './componentes/Footer';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NavBar />
    <Hero />
    <Nosotros />
    <Servicios />
    <Contacto />
    <Footer />
   
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
