import React from 'react';
import '../CSS/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


function Nosotros() {

    return (

        <section class="sobre-mi seccion-oscura">
            <div class="contenedor">
                <h2 class="seccion-titulo">¿QUIENES SOMOS'</h2>
                <p class="seccion-texto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at odio cursus,
                    ultrices nunc rhoncus,
                    auctor ante. Quisque id metus massa. Pellentesque a finibus turpis. Nunc fermentum massa magna,
                    convallis hendrerit dui auctor ut. Maecenas in scelerisque nisi.
                    Cras vel lobortis dui. Ut ac metus sem. In consequat nunc nec euismod pulvinar.</p>

            </div>

        </section>


    );
}

export default Nosotros;