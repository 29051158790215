import React from 'react'; 
import '../CSS/styles.css';
import logo from '../imagenes/logo.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


function NavBar(){

    return (
        <nav className="navbar navbar-expand-md navbar-light">
        <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-toggler"
                aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-toggler">
                <a className="navbar-brand" href="#">
                    <img src={logo} width="50" alt="Logo" />
                </a>
                <ul className="navbar-nav d-flex justify-content-center align-items-center">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">Sobre mi</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Proyectos</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Testimionio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Contacto</a>
                    </li>
                </ul>

            </div>
        </div>
    </nav>

    );
}

export default NavBar;