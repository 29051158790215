import React from 'react';
import '../CSS/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../imagenes/logo.jpg'

function Footer() {

    return (

        <footer class="seccion-oscura d-flex flex-column align-items-center justify-content-center">
            <img class="footer-logo" src={logo} alt="Logo del portafolio"/>
                <p class="footer-texto text-center">Aprendo y creo todos los días.<br />Creemos un proyecto juntos.</p>
                <div class="iconos-redes-sociales d-flex flex-wrap align-items-center justify-content-center">
                   
                    <a href="https://github.com/freeCodeCamp/freeCodeCamp" target="_blank" rel="noopener noreferrer">
                    <i class="bi bi-facebook"></i>
                    </a>
                    <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                        <i class="bi bi-linkedin"></i>
                    </a>
                    <a href="https://www.instagram.com/freecodecamp/" target="_blank" rel="noopener noreferrer">
                        <i class="bi bi-instagram"></i>
                    </a>
                    <a href="mailto:janedoe@micorreo.com" target="_blank" rel="noopener noreferrer">
                        <i class="bi bi-envelope"></i>
                    </a>
                </div>
                <div class="derechos-de-autor">Creado Digital Partners(2023) &#169;</div>
        </footer>




    )
}

export default Footer;