import React from 'react';
import '../CSS/styles.css';
import logo from '../imagenes/marketing.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Hero() {

    return (

        <section className="hero align-content-lg-stretch">
            <div className="hero-principal d-flex flex-column justify-content-center align-items-center">

                <h1>DIGITAL PARTNERS</h1>
                <h2>Hacemos crecer tu negocio</h2>
            </div>
            <div className="hero-inferior d-flex flex-column justify-content-center align-items-center">
                <img className="hero-inferior-imagen img-fluid" src={logo}
                    alt="Monitores y herramientas de desarrollo" />

            </div>
        </section>


    );


}

export default Hero;

