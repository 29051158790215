import React from 'react';
import '../CSS/styles.css';
import logo from '../imagenes/marketing.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Contacto (){

 return (

    <section id="contacto" class="contacto seccion-oscura">
        <div class="container">
          <div class="container text-center rectangulo d-flex justify-content-evenly">
            <div class="row">
              <div class="col-12 col-md-4 seccion-titulo">
                ¡Hablemos!
              </div>
              <div class="col-12 col-md-4 descripcion">
                Contáctanos para iniciar tu proyecto.
              </div>
              <div class="col-12 col-md-4">
                <a href="mailto:janedoe@micorreo.com">
                  <button type="button">  
                    Contacto
                    <i class="bi bi-envelope-check-fill"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

 );

}

export default Contacto;