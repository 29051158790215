import React from 'react';
import '../CSS/styles.css';
import logo from '../imagenes/marketing.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


function Servicios() {

    return (

        <section class="experiencia seccion-clara">
            <div class="container text-center">
                <h2 class="seccion-titulo">NUESTROS SERVIICIOS</h2>
                <div class="row">
                    <div class="columna col-12 col-md-4">
                        <i class="bi bi-laptop"></i>
                        <p class="experiencia-titulo">Desarrollo Web</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum iste recusandae quae tenetur
                            praesentium ex amet quos ea placeat, quia quis ipsum dolor esse nihil error culpa debitis fugit
                            ipsa?</p>



                    </div>
                    <div class="columna col-12 col-md-4">
                        <i class="bi bi-laptop"></i>
                        <p class="experiencia-titulo">Gestion RRSS</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum iste recusandae quae tenetur
                            praesentium ex amet quos ea placeat, quia quis ipsum dolor esse nihil error culpa debitis fugit
                            ipsa?</p>



                    </div>
                    <div class="columna col-12 col-md-4">
                        <i class="bi bi-laptop"></i>
                        <p class="experiencia-titulo">Cración de Contenido</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum iste recusandae quae tenetur
                            praesentium ex amet quos ea placeat, quia quis ipsum dolor esse nihil error culpa debitis fugit
                            ipsa?</p>



                    </div>

                </div>

            </div>

        </section>


    );
}

export default Servicios;